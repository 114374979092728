import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const SendMessage = () => {
  const [number, setNumber] = useState('');
  const [text, setText] = useState('');
  const [delay, setDelay] = useState('');
  const [responseMessage, setResponseMessage] = useState('');

  // Exibir o nome do usuário
  const apelido = localStorage.getItem('apelido');

  // Função para alternar entre seções
  const navigate = (sectionId) => {
    document.querySelectorAll('.content > div').forEach(div => div.classList.add('hidden'));
    document.getElementById(sectionId).classList.remove('hidden');
  };

  // Função para enviar mensagem
  const sendMessage = async () => {
    const numberTrimmed = number.trim();
    const delayValue = delay || 0;

    try {
      const response = await fetch('https://service.tauaapp.com.br/api/send-message', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ number: numberTrimmed, text, delay: parseInt(delayValue) })
      });
      const data = await response.json();
      setResponseMessage(response.ok
        ? `Mensagem enviada com sucesso! ID: ${data.key.id}`
        : `Erro: ${data.error}`);
    } catch (error) {
      setResponseMessage('Erro ao enviar mensagem.');
    }
  };

  return (
    <div>
      <div className="header">
        <h1>Bem-vindo, <span id="userApelido">{apelido || 'Usuário'}</span>!</h1>
      </div>
      <div className="container">
        {/* Sidebar */}
        <div className="sidebar">
          <button onClick={() => navigate('messageForm')}>Mensagem</button>
          <button onClick={() => navigate('contactsList')}>Contatos</button>
        </div>

        {/* Conteúdo */}
        <div className="content">
          {/* Formulário de envio de mensagens */}
          <div id="messageForm">
            <h2>Enviar Mensagem</h2>
            <form id="sendMessageForm" onSubmit={e => e.preventDefault()}>
              <label htmlFor="number">
                <i className="fas fa-phone-alt"></i> Número de Telefone:
              </label>
              <input
                type="text"
                id="number"
                name="number"
                placeholder="+558899999999"
                value={number}
                onChange={e => setNumber(e.target.value)}
                required
              />

              <label htmlFor="text">
                <i className="fas fa-comment-dots"></i> Mensagem:
              </label>
              <textarea
                id="text"
                name="text"
                placeholder="Digite sua mensagem aqui..."
                value={text}
                onChange={e => setText(e.target.value)}
                required
              />

              <label htmlFor="delay">
                <i className="fas fa-clock"></i> Delay (ms):
              </label>
              <input
                type="number"
                id="delay"
                name="delay"
                placeholder="Digite o delay em milissegundos"
                min="0"
                value={delay}
                onChange={e => setDelay(e.target.value)}
              />

              <button type="button" onClick={sendMessage}>Enviar Mensagem</button>
            </form>
            <div id="responseMessage" className="response-message">{responseMessage}</div>
          </div>

          {/* Lista de contatos */}
          <div id="contactsList" className="hidden">
            <h2>Contatos</h2>
            <ul>
              <li>João Silva - +558899999999</li>
              <li>Maria Oliveira - +558899888888</li>
              <li>Pedro Santos - +558899777777</li>
            </ul>
            <p>Lista de contatos fictícia para demonstração.</p>
          </div>
        </div>
      </div>
      <Link to="/">Voltar para a página inicial</Link>
    </div>
  );
};

export default SendMessage;
