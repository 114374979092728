import React from 'react';
import { Routes, Route } from 'react-router-dom';
import SendMessage from './pages/SendMessage';
import Contacts from './pages/Contacts';
import Login from './pages/Login';
import Home from './pages/Home';
import ProtectedRoute from './components/ProtectedRoute';
import './index.css';

const App = () => {
  return (
    <Routes>
      {/* Rota pública */}
      <Route path="/" element={<Login />} />

      {/* Rotas protegidas */}
      <Route path="/dashboard" element={<ProtectedRoute><Home /></ProtectedRoute>}>
        <Route path="send-message" element={<SendMessage />} />
        <Route path="contacts" element={<Contacts />} />
      </Route>
    </Routes>
  );
};

export default App;
