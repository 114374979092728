import React from 'react';

const Contacts = () => {
  return (
    <div>
      <h2>Contatos</h2>
      <p>Esta é a página de contatos.</p>
    </div>
  );
};

export default Contacts;
