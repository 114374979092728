import React, { useEffect, useState } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom'; // Aqui está a correção
import './css/Home.css';


const Home = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const location = useLocation(); // Obtém a rota atual

  // Atualiza `isMobile` ao redimensionar a janela
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isActive = (path) => location.pathname === path; // Verifica se a rota é a atual

  return (
    <div className="home-container">
      {/* AppBar para telas menores */}
      {isMobile ? (
        <div className="appbar">
          <Link to="/dashboard/send-message">
            <button className={`appbar-button ${isActive('/dashboard/send-message') ? 'active' : ''}`}>
              Mensagens
            </button>
          </Link>
          <Link to="/dashboard/contacts">
            <button className={`appbar-button ${isActive('/dashboard/contacts') ? 'active' : ''}`}>
              Contatos
            </button>
          </Link>
        </div>
      ) : (
        /* Sidebar para telas maiores */
        <div className="sidebar">
          <Link to="/dashboard/send-message">
            <button className={`menu-button ${isActive('/dashboard/send-message') ? 'active' : ''}`}>
              Mensagens
            </button>
          </Link>
          <Link to="/dashboard/contacts">
            <button className={`menu-button ${isActive('/dashboard/contacts') ? 'active' : ''}`}>
              Contatos
            </button>
          </Link>
        </div>
      )}

      {/* Conteúdo principal */}
      <div className="main-content">
        <Outlet />
      </div>
    </div>
  );
};

export default Home;
