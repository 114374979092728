import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
  const token = localStorage.getItem('token'); // Verifica se o token está salvo no localStorage.

  // Se o token não existe, redireciona para a página de login.
  return token ? children : <Navigate to="/" />;
};

export default ProtectedRoute;
