// src/service/api_login.js
export const login = async (telefone, senha) => {
  try {
    const response = await fetch('https://service.tauaapp.com.br/api/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ telefone, senha }),
    });

    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message || 'Erro ao realizar o login.');
    }

    return data; // Retorna os dados em caso de sucesso.
  } catch (error) {
    throw new Error(error.message || 'Erro ao conectar com o servidor.');
  }
};
